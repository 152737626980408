import Vue from "vue";

/*TITLE*/
document.title = "Terrazas de Valdebebas | Porcelanosa PARTNERS";
/*PROJECT TYPE*/
Vue.prototype.$typeProject = "PARTNERS";

/*HERO*/
Vue.prototype.$title = "Terrazas de Valdebebas";
Vue.prototype.$subtitle = "¿te quedas a vivir aquí?";

/*INTRO*/
Vue.prototype.$promoter = "Terrazas de Valdebebas";
Vue.prototype.$introSubtitle = "¿te quedas a vivir aquí?";

/*GALLERY*/
Vue.prototype.$image_sittingroom = "salon-salzsburg--20230126050132.jpg";
Vue.prototype.$image_kitchen = "cocina-3bottega-calizanogal-moonbottega-antracita--20230126050147.jpg";
Vue.prototype.$image_bath1 = "banyo1_rivolirivolitanzania-nutsmart-2-cajones--20230126050141.jpg";
Vue.prototype.$image_bath2 = "banyo-2_-bottega-acerobottega-acerospiga-bottega-acero--20230126050100.jpg";
Vue.prototype.$image_room = "";
Vue.prototype.$image_facade = "";

Vue.prototype.$gallery_images_sittingroom = [
  {
    src: "salon-salzsburg--20230126050132.jpg",
  },
  {
    src: "salon-arizona--20230126050139.jpg",
  },
];

Vue.prototype.$gallery_images_kitchen = [
  {
    src: "cocina-3bottega-calizanogal-moonbottega-antracita--20230126050147.jpg",
  },
  {
    src: "cocina-3bottega-acerobisonbottega-acero--20230126050153.jpg",
  },
  {
    src: "cocina-2bottega-caliza-nature-eucalyptus-blondbottega-caliza--20230126050118.jpg",
  },
  {
    src: "cocina-2bottega-aceroblanco-nubolbottega-acero--20230126050126.jpg",
  },
  {
    src: "cocina-1bottega-caliza-nature-eucalyptus-blondbottega-caliza--20230126050134.jpg",
  },
  {
    src: "cocina-1bottega-calizanogal-moonbottega-antracita--20230126050128.jpg",
  },
];

Vue.prototype.$gallery_images_bath1 = [
  {
    src: "banyo1_rivolirivolitanzania-nutsmart-2-cajones--20230126050141.jpg",
  },
  {
    src: "banyo-1_newport-beigenewport-beigeold-beigesmart-2-cajones--20230126050151.jpg",
  },
];

Vue.prototype.$gallery_images_bath2 = [
  {
    src: "banyo-2_-bottega-acerobottega-acerospiga-bottega-acero--20230126050100.jpg",
  },
  {
    src: "banyo-2-_nantes-calizanantes-calizamosaico-nantes-caliza--20230126050106.jpg",
  },
];

Vue.prototype.$gallery_images_room = [
];

Vue.prototype.$gallery_images_facade = [
];

/*GALLERY GRID*/
Vue.prototype.$gallery_grid_sittingroom = "6";
Vue.prototype.$gallery_grid_kitchen = "6";
Vue.prototype.$gallery_grid_bath1 = "3";
Vue.prototype.$gallery_grid_bath2 = "3";
Vue.prototype.$gallery_grid_room = "12";
Vue.prototype.$gallery_grid_facade = "12";

/*TOUR VIRTUAL*/
Vue.prototype.$360 = "https://360.porcelanosapartners.com/VALDEBEBAS/";

/*3D FILM*/
Vue.prototype.$3dFilm = "";

/*CATALOG*/
Vue.prototype.$catalog = true;

/*SPACES*/
Vue.prototype.$spaces = "https://spaces.porcelanosa.com/tag/valdebebas/?e=iframe";

/*PROMOTION LOGO*/
Vue.prototype.$promotion_logo = true;

/*PROMOTER LOGO*/
Vue.prototype.$promoter_logo = true;

/*CONTACT*/
Vue.prototype.$promoter_address = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20estanislao%20pérez%20pita%20nº70%2028055%20madrid",
    text: "C/ Estanislao Pérez Pita nº70, 28055 Madrid",
  },
];

Vue.prototype.$showroom = [
  {
    icon: "mdi-map-marker",
    link: "https://maps.google.com/?q=c%20bahía%20de%20pollensa%20nº5%2028042%20madrid",
    text: "C/Bahía de Pollensa nº5, 28042 Madrid",
  },
];

Vue.prototype.$contact = [
  {
    icon: "mdi-phone",
    link: "telf:911400188",
    text: "911400188",
  },
  {
    icon: "mdi-email",
    link: "mailto:info@terrazasdevaldebebas.com",
    text: "info@terrazasdevaldebebas.com",
  },
];
